jQuery( document ).ready(function($) {
    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    $(".scroll").click(function (e) { 
        e.preventDefault(); 
        var dest = $(this).attr('href'); 
        $('html,body').animate({ scrollTop: $(dest).offset().top }, 'slow'); 
    });

    $('.menu-toggle').on('click', function(e){
        e.preventDefault();
        $('#header nav').toggleClass('open');
        $('.menu-toggle').toggleClass('open');
    })
    
});